

$(document).ready(function() {
    $('.photos').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
    $('.pdfs').magnificPopup({
       type: 'iframe'
   });

   $('.modal a.close-modal').click(function(){
       $($(this).attr('data-target')).removeClass('active');
   });

   $(function () {
       $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
   } );

   $('.workshop-row a.btn').click(function(){
       $($(this).attr('data-target')).addClass('active');
       $($(this).attr('data-target')).css('height',$(document).height());
       $($(this).attr('data-target')+ ' .modal-body').css('top', $(document).scrollTop());
       $($(this).attr('data-target')+' input[name="kurz"]').val($(this).attr('data-title'));
       $($(this).attr('data-target') + ' h4 small').html($(this).attr('data-title'));
   });

   $('input').iCheck({
     checkboxClass: 'icheckbox_minimal-red',
     radioClass: 'iradio_minimal-red',
     increaseArea: '20%' // optional
   });


    var owl = $('.owl-slider');
    var owl2 = $('.photogalery-slider-2');

    owl.on('changed.owl.carousel', function(event) {
        var n = event.item.count;
        x     = event.item.index;
        if(x == 0) x = 2;
        x = x -1 ;
        if(x > n) x = 1;

        $('.steps span').html(x+"/"+n);
        //alert($('.owl-carousel .owl-nav').html());
    });

    $('.steps a.owl-slider-prev').click(function() {
        owl.trigger('prev.owl.carousel');
    });
    $('.steps a.owl-slider-next').click(function() {
        owl.trigger('next.owl.carousel');
    });

    owl.owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        items: 3,
        autoplay:true,
        autoplayTimeout:5000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            }
        }
    });

    owl2.owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        items: 3,
        autoplay:false,
        autoplayTimeout:5000
    });


    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });
    /* END menu for tablets and mobiles*/
    setTimeout(reRender(false), 3000);

    $( window ).resize(function() {
        reRender(false);
    });
    $( document ).scroll(function() {
        reRender(true);
    });
});


function reRender(scroll){
    if(scroll){
        if($( document ).find('.photos')){
            $( ".photos" ).each(function() {
                $(this).find('span').css('width', parseInt($(this).outerWidth())-30).css('height',parseInt($(this).outerHeight()) - 30);
            });
            $('.fotogaleryLink').css('height',parseInt($('.photos').outerHeight())-5);
        }
    }else{
        constWidth = 990;

        if($( document ).find('.rowWithImg') && $(window).width() >= constWidth){
            $( ".rowWithImg" ).each(function() {
                console.log(parseInt($(this).outerHeight()));
                $(this).find('.noPadding img').css('height',parseInt($(this).outerHeight())).css('width','auto');
                $(this).css('height',parseInt($(this).outerHeight()) - 10).css('overflow','hidden');
            });
        }
        if($( document ).find('.rowWithImg') && $(window).width() <= constWidth){
            $( ".rowWithImg" ).each(function() {
                $(this).find('.noPadding img').css('height','100%').css('width','100%');
            });
        }
    }


}
